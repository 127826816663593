import { useFragment } from "react-relay";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "@components/account-selection-panel/account-selection-panel.graphql";
import {
	accountContentClass,
	accountOptionClass,
	accountOptionsWrapperClass,
} from "@components/account-selection-panel/account-selection-panel.styles";
import { AccountSelectionPanelProps } from "@components/account-selection-panel/account-selection-panel.types";
import { Avatar } from "@components/avatar";
import { Icon } from "@components/icon";
import { useAuthContext } from "@hooks/use-auth-context";
import { accountSelectionPanel_AuthViewerSchemaFragment$key } from "@relay/accountSelectionPanel_AuthViewerSchemaFragment.graphql";
import { H4Span } from "@themes/font-tags";

export const AccountSelectionPanel = ({
	authViewerFragmentRef,
	onAccountSelected,
}: AccountSelectionPanelProps) => {
	const auth = useFragment<accountSelectionPanel_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerFragmentRef,
	);

	const { switchAccount } = useAuthContext();

	const accounts = auth.currentUser?.accounts ?? [];

	const createAccountOnClickHandler = (id: string) => () => {
		switchAccount(id).then(onAccountSelected);
	};

	return (
		<div className={accountOptionsWrapperClass}>
			{accounts.map((account) => (
				<div
					className={accountOptionClass}
					key={account.id}
					onClick={createAccountOnClickHandler(account.id)}
				>
					<div className={accountContentClass}>
						<Avatar name={account.name} />
						<H4Span>{account.name}</H4Span>
					</div>
					<Icon icon="arrowRight" sizeInRem={1.5} />
				</div>
			))}
		</div>
	);
};
