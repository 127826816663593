/**
 * @generated SignedSource<<be126ef0c9401fccd68f66892eff579b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConstellationSwitchAccountsMutationInput = {
  clientMutationId?: string | null;
  refreshToken: string;
  targetAccount: string;
};
export type useAuthContext_SwitchAccountMutation$variables = {
  input: ConstellationSwitchAccountsMutationInput;
};
export type useAuthContext_SwitchAccountMutation$data = {
  readonly Auth: {
    readonly constellationSwitchAccounts: {
      readonly loginFromWebappResult: {
        readonly avgsPotentialAnalysisId: string | null;
        readonly isAVGSClient: boolean;
        readonly jwtTokens: {
          readonly accessToken: string;
          readonly refreshToken: string;
        };
      };
    } | null;
  };
};
export type useAuthContext_SwitchAccountMutation = {
  response: useAuthContext_SwitchAccountMutation$data;
  variables: useAuthContext_SwitchAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ConstellationSwitchAccountsMutationPayload",
        "kind": "LinkedField",
        "name": "constellationSwitchAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LoginFromWebappResult",
            "kind": "LinkedField",
            "name": "loginFromWebappResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JwtLoginInformation",
                "kind": "LinkedField",
                "name": "jwtTokens",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refreshToken",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAVGSClient",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avgsPotentialAnalysisId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAuthContext_SwitchAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAuthContext_SwitchAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f203da90d0370920f1f4e609781648b",
    "id": null,
    "metadata": {},
    "name": "useAuthContext_SwitchAccountMutation",
    "operationKind": "mutation",
    "text": "mutation useAuthContext_SwitchAccountMutation(\n  $input: ConstellationSwitchAccountsMutationInput!\n) {\n  Auth {\n    constellationSwitchAccounts(input: $input) {\n      loginFromWebappResult {\n        jwtTokens {\n          accessToken\n          refreshToken\n        }\n        isAVGSClient\n        avgsPotentialAnalysisId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b91f362f04d712d749df30cf9453a50";

export default node;
