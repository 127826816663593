import { box, flex, grid } from "@styled-system/patterns";

export const screenWrapper = flex({
	flexDirection: "column",
	h: "full",
	gap: "32",
	p: "24",
	md: { p: "40" },
	overflowY: "scroll",
});

export const headerWrapper = flex({
	flexDirection: "column",
	gap: "12",
});

export const productItem = flex({
	flexDirection: "column",
	alignSelf: "stretch",
	alignItems: "stretch",
	md: { flexDirection: "row", alignItems: "center" },
	columnGap: "16",
});

export const productCardWrapper = box({
	position: {
		base: "relative",
		md: "static",
	},
	minW: {
		md: "[22rem]",
	},
});

export const controlsPriceWrapper = flex({
	flexDirection: "row",
	flex: "1",
	alignItems: "center",
	alignSelf: "stretch",
});

export const countControls = flex({
	flexDirection: "row",
	flex: "1",
	alignItems: "center",
	md: { justifyContent: "center", w: "[8rem]", paddingX: "16" },
	gap: "16",
	paddingRight: "16",
});

export const inputNumberWrapper = flex({
	flexDirection: "row",
	alignItems: "center",
	md: { minW: "[3rem]" },
});

export const itemPriceWrapper = flex({
	flexDirection: "column",
	alignItems: "end",
	whiteSpace: "nowrap",
	md: { w: "[7rem]" },
});

export const divider = box({
	minH: "[1px]",
	alignSelf: "stretch",
	backgroundColor: "shade.10",
});

export const discountWrapper = flex({
	flexDirection: "column",
	md: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	gap: "16",
});

export const discountTitleTagWrapper = flex({
	flexDirection: "column",
	gap: "8",
});

export const discountTitleWrapper = flex({
	flexDirection: "row",
	gap: "8",
	alignItems: "center",
});

export const discountPercentWrapper = flex({
	flexDirection: "row",
	alignSelf: "end",
	md: { flexDirection: "column" },
	gap: "16",
	alignItems: "end",
});

export const priceWrapper = flex({
	flexDirection: "column",
	gap: "8",
});

export const priceRowsWrapper = flex({
	flexDirection: "row",
	justifyContent: "space-between",
	whiteSpace: "nowrap",
});

export const priceLeftGroupWrapper = flex({
	flexDirection: "column",
	gap: "8",
});

export const priceRightGroupWrapper = flex({
	flexDirection: "column",
	alignItems: "end",
	gap: "8",
	textAlign: "right",
});

export const priceMonthlyWrapper = flex({
	alignSelf: "end",
});

export const discountCodeWrapper = flex({
	flexDirection: "row",
	gap: "16",
});

export const moreProductsWrapper = flex({
	flexDirection: "column",
	gap: "24",
});

export const productsList = grid({
	display: "grid",
	gridTemplateColumns: {
		base: "[repeat(1, 1fr)]",
		md: "[repeat(2, 1fr)]",
		lg: "[repeat(3, 1fr)]",
		xl: "[repeat(2, 1fr)]",
		"2xl": "[repeat(3, 1fr)]",
	},
	gap: "16",
	justifyContent: "center",
});
