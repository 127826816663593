import graphql from "babel-plugin-relay/macro";

export const LOGIN_MUTATION = graphql`
	mutation useAuthContext_LoginMutation($input: LoginFromWebappInput!) {
		Auth {
			loginFromWebapp(input: $input) {
				loginResult {
					jwtTokens {
						accessToken
						refreshToken
					}
					firstLogin
					isAVGSClient
					avgsPotentialAnalysisId
				}
			}
		}
	}
`;

export const LOGOUT_MUTATION = graphql`
	mutation useAuthContext_LogoutMutation($input: LogoutJwtInput!) {
		Auth {
			logoutJwt(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const PERMISSION_QUERY = graphql`
	query useAuthContext_PermissionQuery {
		Viewer {
			Auth {
				currentUser {
					user {
						id
					}
					permissionsInAccount
				}
			}
		}
	}
`;

export const SWITCH_ACCOUNT_MUTATION = graphql`
	mutation useAuthContext_SwitchAccountMutation(
		$input: ConstellationSwitchAccountsMutationInput!
	) {
		Auth {
			constellationSwitchAccounts(input: $input) {
				loginFromWebappResult {
					jwtTokens {
						accessToken
						refreshToken
					}
					isAVGSClient
					avgsPotentialAnalysisId
				}
			}
		}
	}
`;

export const REGISTER_MUTATION = graphql`
	mutation useAuthContext_RegisterMutation($input: RegisterUserInput!) {
		Auth {
			registerUser(input: $input) {
				userId
			}
		}
	}
`;

export const ACCEPT_INVITATION_MUTATION = graphql`
	mutation useAuthContext_AcceptInvitationMutation($input: AcceptInvitationInput!) {
		Auth {
			acceptInvitation(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const ACCEPT_INVITATION_WITH_NEW_USER_MUTATION = graphql`
	mutation useAuthContext_AcceptInvitationWithNewUserMutation(
		$input: AcceptInviteWithNewUserInput!
	) {
		Auth {
			acceptInvitationWithNewUser(input: $input) {
				loginResult {
					jwtTokens {
						accessToken
						refreshToken
					}
					isAVGSClient
					avgsPotentialAnalysisId
				}
			}
		}
	}
`;

export const ACTIVATE_USER_MUTATION = graphql`
	mutation useAuthContext_ActivateUserMutation($input: ActivateUserInput!) {
		Auth {
			activateUser(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const FORGOT_PASSWORD_MUTATION = graphql`
	mutation useAuthContext_ForgotPasswordMutation($input: ForgotPasswordInput!) {
		Auth {
			forgotPassword(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const RESET_PASSWORD_MUTATION = graphql`
	mutation useAuthContext_ResetPasswordMutation($input: ResetPasswordAndActivateInput!) {
		Auth {
			resetPasswordAndActivate(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const QUERY = graphql`
	query useAuthContext_Query {
		Viewer {
			Auth {
				...profileMenu_AuthViewerSchemaFragment
			}
		}
	}
`;
