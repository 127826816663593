import {
	JwtTokenDataSchema,
	LOCAL_STORAGE_LOGIN_DATA_KEY,
	LOCAL_STORAGE_USER_DATA_KEY,
	UserDataSchema,
} from "@slices/auth/auth.consts";
import { AuthState, JwtTokenData, UserData } from "@slices/auth/auth.types";

export const getParsedLoginData = (): JwtTokenData | undefined => {
	try {
		const loginData = localStorage.getItem(LOCAL_STORAGE_LOGIN_DATA_KEY);
		if (!loginData) return undefined;
		const parsedData = JSON.parse(loginData);
		return JwtTokenDataSchema.parse(parsedData);
	} catch {
		return undefined;
	}
};

export const updateParsedLoginData = (data?: JwtTokenData) => {
	const existingData = getParsedLoginData();
	const updatedData = { ...existingData, ...data };
	localStorage.setItem(LOCAL_STORAGE_LOGIN_DATA_KEY, JSON.stringify(updatedData));
};

export const removeParsedLoginData = () => {
	localStorage.removeItem(LOCAL_STORAGE_LOGIN_DATA_KEY);
};

export const getParsedUserData = (): UserData | undefined => {
	try {
		const userData = localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY);
		if (!userData) return undefined;
		const parsedData = JSON.parse(userData);
		return UserDataSchema.parse(parsedData);
	} catch {
		return undefined;
	}
};

export const updateParsedUserData = (data?: UserData) => {
	const existingData = getParsedUserData();
	const updatedData = { ...existingData, ...data };
	localStorage.setItem(LOCAL_STORAGE_USER_DATA_KEY, JSON.stringify(updatedData));
};

export const removeParsedUserData = () => {
	localStorage.removeItem(LOCAL_STORAGE_USER_DATA_KEY);
};

export const getInitialAuthState = (): AuthState => {
	const parsedLoginData = getParsedLoginData();
	const parsedUserData = getParsedUserData();
	return {
		isLoggedIn: Boolean(parsedLoginData),
		loginData: parsedLoginData,
		firstLogin: false,
		isAvgsClient: parsedUserData?.isAvgsClient ?? false,
		redirectPath: parsedUserData?.redirectPath,
	};
};
