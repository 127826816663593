import { InputNumber } from "primereact/inputnumber";
import styled, { css } from "styled-components";
import { shade100, shade20, shade40, shade5 } from "@themes/colors";
import { TkaP2Typography } from "@themes/font-tags";

const InputCSS = css`
	.p-inputtext {
		${TkaP2Typography};
		color: ${shade100};
		box-shadow: none !important;
		border-radius: 0.25rem;
		width: 100%;
		border: 1px solid ${shade40};
	}

	&:disabled {
		background-color: ${shade5};
		color: ${shade20};
		border-style: none;
	}
`;
export const StyledInputNumber = styled(InputNumber)`
	${InputCSS}
`;
