import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AccountSwitchMenu } from "@components/account-switch-menu";
import { SwitchAccountResult } from "@hooks/use-auth-context/use-auth-context.types";
import { inlineAuthGuard_AuthViewerSchemaFragment$key } from "@relay/inlineAuthGuard_AuthViewerSchemaFragment.graphql";
import { Path } from "@router/paths";
import { selectIsLoggedIn } from "@slices/auth/auth.selectors";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./inline-auth-guard.graphql";
import { Center, MinWidth } from "./inline-auth-guard.styles";
import { AuthViews, InlineAuthControllerProps } from "./inline-auth-guard.types";
import { ForgotPasswordSteps } from "../forgot-password-steps";
import { Loader } from "../Loader";
import { LoginSteps } from "../login-steps";
import { SignUpSteps } from "../sign-up-steps";

export const InlineAuthGuard = ({
	children,
	authViewerSchemaFragmentRef,
}: InlineAuthControllerProps) => {
	const auth = useFragment<inlineAuthGuard_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const [authView, setAuthView] = useState<AuthViews>("login");
	const [accountSelected, setAccountSelected] = useState(true);
	const navigate = useNavigate();

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const handleOnGoToLogin = () => {
		setAuthView("login");
	};

	const handleOnGoToSignUp = () => {
		setAuthView("signup");
	};

	const handleOnGoToForgotPassword = () => {
		setAuthView("forgot-password");
	};

	const handleOnAccountSelected = (result: SwitchAccountResult) => {
		setAccountSelected(true);
		const isAvgsClient = result?.isAVGSClient;
		if (!(result && isAvgsClient)) return;

		const potentialAnalysisId = result.avgsPotentialAnalysisId ?? "";
		navigate(Path.avgsPotentialAnalysis.withId(potentialAnalysisId).path, {
			replace: true,
		});
	};

	useEffect(() => {
		if (!isLoggedIn) setAccountSelected(false);
	}, [isLoggedIn]);

	const accountsLoaded = auth.currentUser?.accounts !== undefined;
	const hasMoreThenOneAccount = accountsLoaded && auth.currentUser.accounts.length > 1;
	const authCompleted =
		isLoggedIn && (accountSelected || !hasMoreThenOneAccount) && accountsLoaded;
	const needToSelectAccount = isLoggedIn && !accountSelected && hasMoreThenOneAccount;
	const showLoading = !accountsLoaded && isLoggedIn;

	const renderAuthStep = needToSelectAccount ? (
		<AccountSwitchMenu
			hideSelectedAccount
			authViewerSchemaFragmentRef={auth}
			onAccountSelected={handleOnAccountSelected}
		/>
	) : showLoading ? (
		<Loader />
	) : authView === "login" ? (
		<LoginSteps
			inline
			onGoToSignUp={handleOnGoToSignUp}
			onGoToForgotPassword={handleOnGoToForgotPassword}
		/>
	) : authView === "signup" ? (
		<SignUpSteps inline onGoToLogin={handleOnGoToLogin} />
	) : (
		<ForgotPasswordSteps inline onGoToLogin={handleOnGoToLogin} />
	);

	const renderContent = authCompleted ? (
		children
	) : (
		<Center>
			<MinWidth>{renderAuthStep}</MinWidth>
		</Center>
	);

	return <>{renderContent}</>;
};
