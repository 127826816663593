import { useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AccountSwitchMenu } from "@components/account-switch-menu";
import { OverlayPanel } from "@components/overlay-panel";
import { HTMLMouseEvent } from "@customTypes/index";
import { type SwitchAccountResult } from "@hooks/use-auth-context/use-auth-context.types";
import { useWindowSize } from "@hooks/use-window-size";
import { avatarWithAccountSwitch_AuthViewerSchemaFragment$key } from "@relay/avatarWithAccountSwitch_AuthViewerSchemaFragment.graphql";
import { Path } from "@router/paths";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./avatar-with-account-switch.graphql";
import {
	AvatarWrapper,
	ContentWrapper,
	FullScreenWrapper,
	Wrapper,
} from "./avatar-with-account-switch.styles";
import { AvatarWithAccountSwitchProps } from "./avatar-with-account-switch.types";
import { Header } from "../header";
import { UserAvatar } from "../user-avatar";

export const AvatarWithAccountSwitch = ({
	authViewerSchemaFragmentRef,
}: AvatarWithAccountSwitchProps) => {
	const auth = useFragment<avatarWithAccountSwitch_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const navigate = useNavigate();

	const overlayPanelRef = useRef<OverlayPanel>(null);
	const [showAccountSwitchMenu, setShowAccountSwitchMenu] = useState(false);

	const { isMediumUp } = useWindowSize();

	const handleChildrenOnClick = (event: HTMLMouseEvent) => {
		overlayPanelRef.current?.toggle(event);
		setShowAccountSwitchMenu(true);
	};

	const handleCloseOnClick = () => {
		setShowAccountSwitchMenu(false);
	};

	const handleAccountSelected = (loginFromWebappResult: SwitchAccountResult) => {
		const isAvgsClient = loginFromWebappResult?.isAVGSClient;
		if (!(loginFromWebappResult && isAvgsClient)) return;

		const potentialAnalysisId = loginFromWebappResult.avgsPotentialAnalysisId ?? "";
		navigate(Path.avgsPotentialAnalysis.withId(potentialAnalysisId).path, {
			replace: true,
		});
	};

	useEffect(() => {
		setShowAccountSwitchMenu(false);
	}, [isMediumUp]);

	const renderMenu = (
		<AccountSwitchMenu
			authViewerSchemaFragmentRef={auth}
			onAccountSelected={handleAccountSelected}
		/>
	);
	const renderMenuWithOverlay = isMediumUp ? (
		<OverlayPanel ref={overlayPanelRef}>{renderMenu}</OverlayPanel>
	) : (
		showAccountSwitchMenu && (
			<FullScreenWrapper>
				<Header leftIcon="close" onLeftItemClick={handleCloseOnClick} />
				<ContentWrapper>{renderMenu}</ContentWrapper>
			</FullScreenWrapper>
		)
	);

	if (!auth.currentUser) return null;
	return (
		<Wrapper>
			{renderMenuWithOverlay}
			<AvatarWrapper onClick={handleChildrenOnClick}>
				<UserAvatar userFragmentRef={auth.currentUser.user} />
			</AvatarWrapper>
		</Wrapper>
	);
};
